@font-face {
  font-family: "Poppins";
  src: url(../assets/fonts/poppins/Poppins-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url(../assets/fonts/poppins/Poppins-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url(../assets/fonts/source-sans-pro/SourceSansPro-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url(../assets/fonts/source-sans-pro/SourceSansPro-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url(../assets/fonts/montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "TrashHand";
  src: url(../assets/fonts/trash-hand/trash-hand.ttf);
}

* {
  font-family: Source Sans Pro;
}
