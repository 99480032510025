.--ion-loading {
  // --backdrop-opacity: 0.9;
  .loading-wrapper {
    background-color: transparent;
    box-shadow: none;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .loading-content {
      background-image: url("/assets/images/loading.png");
      animation: spin 1s linear infinite;
      width: 100px;
      height: 100px;

      // background-color: transparent;
    }
  }
}
