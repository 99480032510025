/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// CUSTOM STYLES
@import "./styles/fonts.scss";
@import "./styles/ion-grid.scss";
@import "./styles/ion-loading.scss";
@import "./styles/chat-more-options-popover.scss";
@import "./styles/modal-experience-filter.scss";
@import "./styles/shared-modal-structure.scss";
@import "./styles/user-modal-croppie-avatar.scss";
@import "./styles/skeleton.scss";
@import "./styles/time-picker.scss";
@import "./styles/toast.scss";
@import "./styles/notification-pop-over.scss";
@import "./styles/user-pop-over-profile.scss";
@import "./styles/print.scss";
@import "./styles/mat-menu.scss";
@import "./styles/full-calendar.scss";
