@media print {
  @page {
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
  }
}

.--print-session {
  // page-break-before: auto;
  // page-break-after: auto;
  break-before: page;
}
