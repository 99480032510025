.--mat-menu {
  .mat-menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      width: 100%;
      padding: 0px 6px 6px 4px;
      font-size: 14px;
      text-align: center;
      border-bottom: 0.5px solid lightgray;
      pointer-events: none;
    }

    .mat-menu-item {
      display: flex;
      align-items: center;
      .mat-icon {
        margin-right: 5px;
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
      &.selected {
        .mat-icon {
          color: var(--ion-color-primary);
        }
        span {
          color: var(--ion-color-primary);
        }
      }
    }
  }
}
